import {memo, useEffect} from 'react';
import {
	IconBlockquote,
	IconBold,
	IconCode,
	IconHeading,
	IconItalic,
	IconList,
	IconListNumbers,
	IconStrikethrough,
} from '@tabler/icons-react';
import {EditorContent, useEditor} from '@tiptap/react';
import {StarterKit} from '@tiptap/starter-kit';
import clsx from 'clsx';

import type {EditorOptions} from '@tiptap/react';
import type {ComponentPropsWithoutRef, ReactNode} from 'react';
import Separator from '../separator';
import {textFieldInputStyles} from '../text-field';
import ToggleButton from '../toggle-button';

export type RichTextProps = {
	placeholder?: ReactNode;
	content?: string | null;
	uncontrolledContent?: string;
	onContentChange?: (content: string) => void;
	readonly?: boolean;
	options?: Partial<EditorOptions>;
	toolbarPrefix?: ReactNode;
	toolbarSuffix?: ReactNode;
} & Omit<ComponentPropsWithoutRef<'div'>, 'content'>;

const RichText = memo(
	({
		content,
		uncontrolledContent,
		onContentChange,
		readonly,
		options,
		toolbarPrefix,
		toolbarSuffix,
		className,
		...props
	}: RichTextProps) => {
		const shouldShowToolbar = !readonly || toolbarPrefix || toolbarSuffix;

		const editor = useEditor({
			immediatelyRender: false,
			editable: !readonly,
			extensions: [StarterKit],
			content,
			editorProps: {
				attributes: {
					class: clsx([
						'prose dark:prose-invert prose-tight focus:outline-none min-w-full',
						shouldShowToolbar ? textFieldInputStyles() : '',
					]),
				},
			},
			onUpdate: ({editor}) => {
				onContentChange?.(editor.getHTML());
			},
			...options,
		});

		useEffect(() => {
			editor?.setEditable(!readonly);
		}, [editor, readonly]);

		useEffect(() => {
			if (editor && uncontrolledContent) {
				editor.commands.setContent(uncontrolledContent, true);
			}
		}, [editor, uncontrolledContent]);

		return (
			<div
				{...props}
				className={clsx(
					shouldShowToolbar ? 'hover:border-mauve8 hover:bg-mauve4 active:bg-mauve5' : '',
					'relative flex h-fit flex-col rounded-sm border border-mauve7 bg-mauve3 px-3 py-2 text-mauve11 focus-visible:outline-mauve8 [&_*]:whitespace-pre-wrap [&_*]:break-words',
					className,
				)}
				onFocus={() => editor?.commands.focus()}
			>
				<div className="absolute left-0 top-0 flex w-full items-center">
					{toolbarPrefix}
					{!readonly && (
						<>
							<ToggleButton
								as="div"
								className="rounded-none"
								icon={IconBold}
								intent="ghost"
								onPress={() => editor?.commands.toggleBold()}
								pressed={editor?.isActive('bold')}
								role="button"
								size="sm"
							/>
							<ToggleButton
								as="div"
								className="rounded-none"
								icon={IconItalic}
								intent="ghost"
								onPress={() => editor?.commands.toggleItalic()}
								pressed={editor?.isActive('italic')}
								role="button"
								size="sm"
							/>
							<ToggleButton
								as="div"
								className="rounded-none"
								icon={IconHeading}
								intent="ghost"
								onPress={() => editor?.commands.toggleHeading({level: 3})}
								pressed={editor?.isActive('heading', {level: 3})}
								role="button"
								size="sm"
							/>
							<ToggleButton
								as="div"
								className="rounded-none"
								icon={IconStrikethrough}
								intent="ghost"
								onPress={() => editor?.commands.toggleStrike()}
								pressed={editor?.isActive('strike')}
								role="button"
								size="sm"
							/>
							<Separator className="h-5" orientation="vertical" />
							<ToggleButton
								as="div"
								className="rounded-none"
								icon={IconList}
								intent="ghost"
								onPress={() => editor?.commands.toggleBulletList()}
								pressed={editor?.isActive('bulletList')}
								role="button"
								size="sm"
							/>
							<ToggleButton
								as="div"
								className="rounded-none"
								icon={IconListNumbers}
								intent="ghost"
								onPress={() => editor?.commands.toggleOrderedList()}
								pressed={editor?.isActive('orderedList')}
								role="button"
								size="sm"
							/>
							<Separator className="h-5" orientation="vertical" />
							<ToggleButton
								as="div"
								className="rounded-none"
								icon={IconBlockquote}
								intent="ghost"
								onPress={() => editor?.commands.toggleBlockquote()}
								pressed={editor?.isActive('blockquote')}
								role="button"
								size="sm"
							/>
							<ToggleButton
								as="div"
								className="rounded-l-none"
								icon={IconCode}
								intent="ghost"
								onPress={() => editor?.commands.toggleCodeBlock()}
								pressed={editor?.isActive('codeBlock')}
								role="button"
								size="sm"
							/>
						</>
					)}
					<div className="ml-auto">{toolbarSuffix}</div>
				</div>

				<EditorContent
					className={clsx(shouldShowToolbar ? 'pt-8' : '', 'flex grow flex-col')}
					editor={editor}
					onClick={event => event.preventDefault()}
				/>
			</div>
		);
	},
);

export default RichText;
