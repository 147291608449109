import {type ComponentPropsWithoutRef, forwardRef} from 'react';
import {twMerge} from 'tailwind-merge';

import {buttonGroupStyles} from './styles';
import type {ButtonGroupVariantProps} from './styles';

export type ButtonGroupProps = ButtonGroupVariantProps & ComponentPropsWithoutRef<'div'>;

const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>(({className, ...props}, forwardedRef) => (
	<div {...props} className={twMerge(buttonGroupStyles(props), className)} ref={forwardedRef} />
));

ButtonGroup.displayName = 'ButtonGroup';

export default ButtonGroup;

export * from './styles';
