import {useForm} from 'react-hook-form';
import {FormInput} from '~/shared/components/inputs/form-input';
import type {UpsertResumeFormSchema} from '~/shared/schemas/resume';
import {getDefaultUpsertResumeFormValues, upsertResumeSchema} from '~/shared/schemas/resume';
import {getResumeName} from '~/shared/utils/resume';
import {zodResolver} from '@hookform/resolvers/zod';
import {Button, TextButton, TextField} from '@job-ish/ui/components';
import {IconAlphabetLatin, IconFileText} from '@tabler/icons-react';

import type {Resume} from '@job-ish/database/types';
import type {FormEventHandler} from 'react';
import type {SubmitHandler} from 'react-hook-form';

type UpsertResumeFormProps = {
	resume: Partial<Resume>;
	onSubmit: SubmitHandler<UpsertResumeFormSchema>;
	onReset?: () => void;
};

export const UpsertResumeForm = ({resume, onSubmit, onReset}: UpsertResumeFormProps) => {
	const {control, handleSubmit, reset} = useForm<UpsertResumeFormSchema>({
		resolver: zodResolver(upsertResumeSchema),
		defaultValues: getDefaultUpsertResumeFormValues(resume),
	});

	const submitForm: FormEventHandler<HTMLFormElement> = async event => {
		event.preventDefault();
		event.stopPropagation();
		await handleSubmit(onSubmit)();
	};

	const resetForm: FormEventHandler<HTMLFormElement> = event => {
		event.preventDefault();
		event.stopPropagation();
		reset();
		onReset?.();
	};

	return (
		<form
			className="flex h-full w-full grow flex-col overflow-hidden"
			onReset={resetForm}
			onSubmit={submitForm}
		>
			<div className="flex h-full max-h-full w-full flex-col gap-4 overflow-y-auto p-2">
				<div className="flex h-fit grow flex-col gap-2">
					<FormInput
						control={control}
						label="Resume Name"
						name="name"
						render={field => (
							<TextField
								{...field}
								placeholder="Enter resume name"
								prefix={<IconAlphabetLatin className="h-4 w-4" />}
							/>
						)}
					/>
					<FormInput
						control={control}
						label="Resume File"
						name="file_path"
						render={field => (
							<TextField
								prefix={<IconFileText className="h-4 w-4" />}
								readonly
								value={getResumeName(field.value)}
							/>
						)}
					/>
				</div>
			</div>
			<div className="mt-auto flex items-center justify-end gap-3 px-2 pt-4">
				<TextButton type="reset">Reset</TextButton>
				<Button color="primary" type="submit">
					{resume.name ? 'Update' : 'Save'}
				</Button>
			</div>
		</form>
	);
};
