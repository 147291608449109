export const getEnvironmentBaseUrl = () => {
	let url =
		process.env.NEXT_PUBLIC_VERCEL_PROJECT_PRODUCTION_URL ||
		process.env.NEXT_PUBLIC_SITE_URL ||
		process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL ||
		process.env.NEXT_PUBLIC_VERCEL_URL ||
		`http://localhost:${process.env.NEXT_PUBLIC_PORT}`;
	url = url.includes('http') ? url : `https://${url}`;
	url = url.at(-1) === '/' ? url : `${url}/`;
	return url;
};

export const isValidUrl = (url?: string) => {
	try {
		if (!url) return false;
		return Boolean(new URL(url));
	} catch {
		return false;
	}
};
