import {forwardRef} from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import Paper from '../paper';
import type {PaperVariantProps} from '../paper/styles';

export type PopoverTriggerProps = PopoverPrimitive.PopoverTriggerProps;
const PopoverTrigger = forwardRef<HTMLButtonElement, PopoverTriggerProps>(
	({asChild, className, ...props}, forwardedRef) => (
		<PopoverPrimitive.Trigger
			{...props}
			aria-label={props['aria-label'] || 'Open popover'}
			asChild={asChild}
			className={twMerge('focus-visible:outline focus-visible:outline-mauve8', className)}
			ref={forwardedRef}
		/>
	),
);

export type PopoverAnchorProps = PopoverPrimitive.PopoverAnchorProps;
const PopoverAnchor = PopoverPrimitive.Anchor;

export type PopoverContentProps = Omit<PopoverPrimitive.PopoverContentProps, 'asChild'> &
	PaperVariantProps & {portal?: boolean};
const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
	(
		{portal = true, bordered = true, padding = 'xs', shadow = true, children, className, ...props},
		forwardedRef,
	) => {
		const content = (
			<PopoverPrimitive.Content
				{...props}
				asChild
				/**
				 * @see https://github.com/radix-ui/primitives/issues/1159
				 */
				onWheel={e => {
					e.stopPropagation();
					e.currentTarget.dispatchEvent(
						new KeyboardEvent('keydown', {key: e.deltaY > 0 ? 'ArrowDown' : 'ArrowUp'}),
					);
					props.onWheel?.(e);
				}}
				ref={forwardedRef}
			>
				<Paper
					bordered={bordered}
					className={twMerge(
						'relative h-fit min-h-[3rem] data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
						className,
					)}
					padding={padding}
					shadow={shadow}
				>
					{children}
				</Paper>
			</PopoverPrimitive.Content>
		);

		return portal ? <PopoverPrimitive.Portal>{content}</PopoverPrimitive.Portal> : content;
	},
);

export type PopoverProps = PopoverPrimitive.PopoverProps;
const Popover = assign((props: PopoverProps) => <PopoverPrimitive.Root {...props} />, {
	Trigger: PopoverTrigger,
	Anchor: PopoverAnchor,
	Content: PopoverContent,
});

Popover.Trigger.displayName = 'Popover.Trigger';
Popover.Anchor.displayName = 'Popover.Anchor';
Popover.Content.displayName = 'Popover.Content';

export default Popover;
