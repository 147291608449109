import {Button} from '@job-ish/ui/components';
import {IconTrash} from '@tabler/icons-react';

type DeleteResumeFormProps = {
	onSubmit: () => void;
	onCancel: () => void;
};

export const DeleteResumeForm = ({onCancel, onSubmit}: DeleteResumeFormProps) => (
	<div className="mt-auto flex items-center justify-end gap-3 px-2 pt-4">
		<Button intent="ghost" onPress={onCancel} type="button">
			Cancel
		</Button>
		<Button color="danger" iconRight={IconTrash} onPress={onSubmit}>
			Delete
		</Button>
	</div>
);
