import {forwardRef, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {IconFileUpload, IconFolders} from '@tabler/icons-react';
import {twMerge} from 'tailwind-merge';

import type {ComponentPropsWithoutRef} from 'react';
import type {DropzoneOptions} from 'react-dropzone';
import Button from '../button';
import Empty from '../empty';
import Overlay from '../overlay';
import Paper from '../paper';
import type {EmptyProps} from '../empty';

export type UploadProps = DropzoneOptions & Omit<EmptyProps, 'icon'> & ComponentPropsWithoutRef<'div'>;

const Upload = forwardRef<HTMLDivElement, UploadProps>(
	({title, subtitle, className, ...props}, forwardedRef) => {
		const [loading, setLoading] = useState(false);

		const {getRootProps, getInputProps, open, isDragActive} = useDropzone({
			disabled: loading,
			...props,
			onDrop: (...args) => {
				setLoading(true);
				props.onDrop?.(...args);
			},
			onDropAccepted: (...args) => {
				setLoading(false);
				props.onDropAccepted?.(...args);
			},
			onDropRejected: (...args) => {
				setLoading(false);
				props.onDropRejected?.(...args);
			},
			onError: error => {
				setLoading(false);
				props.onError?.(error);
			},
			noClick: true,
		});

		return (
			<Paper bordered className={twMerge('relative', className)} {...getRootProps()}>
				<Empty
					className={getRootProps().className}
					icon={IconFileUpload}
					ref={forwardedRef}
					subtitle={subtitle}
					title={title}
				>
					<input {...getInputProps()} />
					<Button color="primary" iconRight={IconFolders} onPress={open} size="sm">
						Browse Files
					</Button>
				</Empty>
				{(isDragActive || loading) && <Overlay loading={loading} />}
			</Paper>
		);
	},
);

Upload.displayName = 'Upload';

export default Upload;
