import {forwardRef} from 'react';
import {IconCheck} from '@tabler/icons-react';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import type {ElementRef} from 'react';
import Command from '../command';
import DropdownMenu from '../dropdown-menu';
import Popover from '../popover';
import type {CommandItemProps, CommandListProps, CommandProps} from '../command';
import type {PopoverContentProps, PopoverProps} from '../popover';

export type ComboboxContentProps = PopoverContentProps &
	CommandProps & {
		portal?: boolean;
	};
const ComboboxContent = forwardRef<HTMLDivElement, ComboboxContentProps>(
	({children, loop, label, value, onValueChange, filter, shouldFilter, portal, className, ...props}, ref) => (
		<Popover.Content {...props} className={twMerge('p-0', className)} forceMount portal={portal} ref={ref}>
			<Command
				filter={filter}
				label={label}
				loop={loop}
				onValueChange={onValueChange}
				shouldFilter={shouldFilter}
				value={value}
			>
				{children}
			</Command>
		</Popover.Content>
	),
);

export type ComboboxItemProps = Omit<CommandItemProps, 'suffix'> & {checked?: boolean; asChild?: boolean};
const ComboboxItem = forwardRef<ElementRef<typeof Command.Item>, ComboboxItemProps>(
	({children, checked, asChild, ...props}, ref) => (
		<Command.Item {...props} asChild={asChild} ref={ref}>
			{children}
			{checked && !asChild && <IconCheck className="ml-auto h-4 w-4 shrink-0" />}
		</Command.Item>
	),
);

export type ComboboxListProps = CommandListProps;
const ComboboxList = forwardRef<HTMLDivElement, ComboboxListProps>(({children, className, ...props}, ref) => (
	<Command.List {...props} className={twMerge('max-h-[24rem]', className)} ref={ref}>
		{children}
	</Command.List>
));

export type ComboboxProps = PopoverProps;
const Combobox = assign((props: PopoverProps) => <Popover {...props} />, {
	Trigger: Popover.Trigger,
	Content: ComboboxContent,
	Input: Command.Input,
	List: ComboboxList,
	Empty: Command.Empty,
	Group: Command.Group,
	Separator: Command.Separator,
	Item: ComboboxItem,
	Label: DropdownMenu.Label,
});

Combobox.Trigger.displayName = 'Combobox.Trigger';
Combobox.Content.displayName = 'Combobox.Content';
Combobox.Input.displayName = 'Combobox.Input';
Combobox.List.displayName = 'Combobox.List';
Combobox.Empty.displayName = 'Combobox.Empty';
Combobox.Group.displayName = 'Combobox.Group';
Combobox.Separator.displayName = 'Combobox.Separator';
Combobox.Item.displayName = 'Combobox.Item';
Combobox.Label.displayName = 'Combobox.Label';

export default Combobox;
