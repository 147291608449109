import {z} from 'zod';

import type {Resume} from '@job-ish/database/types';

export const upsertResumeSchema = z.object({
	name: z.string().min(1, 'Resume name is required'),
	file_path: z.string({required_error: 'File is required', invalid_type_error: 'File is required'}).min(1),
});

export type UpsertResumeFormSchema = z.infer<typeof upsertResumeSchema>;

export const getDefaultUpsertResumeFormValues = (resume?: Partial<Resume>) => ({
	name: resume?.name ?? resume?.file_path ?? undefined,
	file_path: resume?.file_path ?? undefined,
});
