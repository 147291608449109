import {createElement, forwardRef, useImperativeHandle, useRef} from 'react';
import {IconSearch} from '@tabler/icons-react';
import {Command as CommandPrimitive} from 'cmdk';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import type {ComponentPropsWithoutRef, ElementRef, ReactNode} from 'react';
import {dropdownMenuItemStyles} from '../dropdown-menu';
import LoadingSpinner from '../loading-spinner';
import {paperStyles} from '../paper';
import {separatorStyles} from '../separator';
import {textFieldInputStyles, textFieldStyles} from '../text-field';
import type {Icon} from '../../types';
import type {DropdownMenuItemVariantProps} from '../dropdown-menu';
import type {TextFieldVariantProps} from '../text-field';

export type CommandInputProps = Omit<ComponentPropsWithoutRef<typeof CommandPrimitive.Input>, 'readOnly'> &
	Omit<TextFieldVariantProps, 'textarea'> & {loading?: boolean; suffix?: ReactNode};
const CommandInput = forwardRef<ElementRef<typeof CommandPrimitive.Input>, CommandInputProps>(
	({className, disabled, readonly, size, subtle, invalid, loading, suffix, ...props}, forwardedRef) => {
		const ref = useRef<HTMLInputElement>(null);

		useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement);

		return (
			<div
				className={twMerge(
					textFieldStyles({disabled, readonly, size, subtle, invalid, className: 'border-none'}),
					className,
				)}
				cmdk-input-wrapper=""
			>
				{loading ? <LoadingSpinner size="sm" /> : <IconSearch className="h-4 w-4" />}
				<CommandPrimitive.Input {...props} className={textFieldInputStyles()} ref={ref} />
				{suffix}
			</div>
		);
	},
);

export type CommandListProps = ComponentPropsWithoutRef<typeof CommandPrimitive.List>;
const CommandList = forwardRef<ElementRef<typeof CommandPrimitive.List>, CommandListProps>(
	({className, ...props}, ref) => (
		<CommandPrimitive.List {...props} className={twMerge('h-fit overflow-auto', className)} ref={ref} />
	),
);

export type CommandEmptyProps = ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>;
const CommandEmpty = forwardRef<ElementRef<typeof CommandPrimitive.Empty>, CommandEmptyProps>(
	(props, ref) => (
		<CommandPrimitive.Empty className="py-6 text-center text-sm text-mauve11" ref={ref} {...props} />
	),
);

export type CommandGroupProps = ComponentPropsWithoutRef<typeof CommandPrimitive.Group>;
const CommandGroup = forwardRef<ElementRef<typeof CommandPrimitive.Group>, CommandGroupProps>(
	({className, ...props}, ref) => (
		<CommandPrimitive.Group
			{...props}
			className={twMerge(
				'p-1 text-mauve12 [&_[cmdk-group-heading]]:bg-mauve3 [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-mauve11',
				className,
			)}
			ref={ref}
		/>
	),
);

export type CommandSeparatorProps = ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>;
const CommandSeparator = forwardRef<ElementRef<typeof CommandPrimitive.Separator>, CommandSeparatorProps>(
	({className, ...props}, ref) => (
		<CommandPrimitive.Separator
			{...props}
			className={twMerge(separatorStyles({orientation: 'horizontal', className: 'w-full'}), className)}
			ref={ref}
		/>
	),
);

export type CommandItemProps = ComponentPropsWithoutRef<typeof CommandPrimitive.Item> &
	DropdownMenuItemVariantProps & {suffix?: ReactNode; icon?: Icon; asChild?: boolean};
const CommandItem = forwardRef<ElementRef<typeof CommandPrimitive.Item>, CommandItemProps>(
	({className, suffix, icon, destructive, children, asChild, ...props}, ref) =>
		asChild ? (
			<CommandPrimitive.Item {...props} ref={ref}>
				{children}
			</CommandPrimitive.Item>
		) : (
			<CommandPrimitive.Item
				{...props}
				className={twMerge(
					dropdownMenuItemStyles({destructive}),
					'h-8 select-none data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50',
					className,
				)}
				ref={ref}
			>
				<div className="flex w-full grow items-center gap-1.5 text-sm">
					{icon && createElement(icon, {className: 'h-4 w-4 shrink-0'})}
					{children}
				</div>
				{suffix && <div className="ml-auto shrink-0">{suffix}</div>}
			</CommandPrimitive.Item>
		),
);

export type CommandProps = ComponentPropsWithoutRef<typeof CommandPrimitive>;
const Command = assign(
	forwardRef<ElementRef<typeof CommandPrimitive>, CommandProps>(({className, ...props}, ref) => (
		<CommandPrimitive
			{...props}
			className={twMerge(paperStyles({shadow: true, padding: 'none', bordered: true}), className)}
			ref={ref}
		/>
	)),
	{
		Input: CommandInput,
		List: CommandList,
		Empty: CommandEmpty,
		Group: CommandGroup,
		Separator: CommandSeparator,
		Item: CommandItem,
	},
);

Command.displayName = CommandPrimitive.displayName;
Command.Input.displayName = 'Command.Input';
Command.List.displayName = 'Command.List';
Command.Empty.displayName = 'Command.Empty';
Command.Group.displayName = 'Command.Group';
Command.Separator.displayName = 'Command.Separator';
Command.Item.displayName = 'Command.Item';

export default Command;
