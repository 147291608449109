import {useCallback, useState} from 'react';
import {UpsertResumeForm} from '~/shared/components/forms/resume/upsert';
import {QUERY_KEYS} from '~/shared/constants/keys';
import {useUploadResumeFile, useUpsertResume} from '~/shared/data/resume';
import type {UpsertResumeFormSchema} from '~/shared/schemas/resume';
import {Dialog, Overlay, Upload} from '@job-ish/ui/components';
import {useToast} from '@job-ish/ui/hooks';
import {useQueryClient} from '@tanstack/react-query';

import type {Resume} from '@job-ish/database/types';
import type {ReactNode} from 'react';
import type {SubmitHandler} from 'react-hook-form';
import type {SetOptional} from 'type-fest';

export type ResumeUploadDialogProps = {
	trigger?: ReactNode;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
	onUploaded?: (id?: number) => void;
	resume?: Resume;
};

export const ResumeUploadDialog = ({
	trigger,
	open,
	onOpenChange,
	onUploaded,
	resume,
}: ResumeUploadDialogProps) => {
	const queryClient = useQueryClient();
	const {mutate: upsertResume, isPending: isUpserting} = useUpsertResume();
	const {mutate: uploadResumeFile, isPending: isUploading} = useUploadResumeFile();
	const [file, setFile] = useState<File>();

	const {show: showErrorToast} = useToast({
		accent: 'danger',
		accentPosition: 'left',
		title: 'Upload Failed',
		description: 'There was an error uploading your resume. Please try again.',
		duration: 1500,
	});

	const handleOpenChange = useCallback(
		(open: boolean) => {
			onOpenChange?.(open);
		},
		[onOpenChange],
	);

	const upsertResumeHandler = useCallback(
		(data: SetOptional<Resume, 'created_at' | 'id' | 'user_id'>) =>
			upsertResume(data, {
				onSuccess: async data => {
					await queryClient.invalidateQueries({queryKey: QUERY_KEYS.Resumes});
					await queryClient.refetchQueries({queryKey: QUERY_KEYS.Resumes});
					onUploaded?.(data?.id);
				},
				onSettled: async () => handleOpenChange(false),
			}),
		[upsertResume, onUploaded, queryClient, handleOpenChange],
	);

	const handleResumeUpsert: SubmitHandler<UpsertResumeFormSchema> = useCallback(
		formData => {
			if (file) {
				uploadResumeFile(
					{file, file_path: formData.file_path},
					{onSuccess: data => upsertResumeHandler({...resume, file_path: data?.path, name: formData.name})},
				);
			} else if (resume) {
				upsertResumeHandler({...resume, name: formData.name});
			}
		},
		[file, resume, uploadResumeFile, upsertResumeHandler],
	);

	return (
		<Dialog onOpenChange={handleOpenChange} open={open}>
			<Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
			<Dialog.Content>
				{(isUploading || isUpserting) && <Overlay loading />}
				<Dialog.Title>Upload Resume</Dialog.Title>
				{file || resume ? (
					<UpsertResumeForm
						onReset={() => setFile(undefined)}
						onSubmit={handleResumeUpsert}
						resume={{file_path: file?.name, ...resume}}
					/>
				) : (
					<Upload
						accept={{'text/plain': ['.pdf']}}
						maxFiles={1}
						maxSize={5_242_880}
						onDropAccepted={acceptedFiles => setFile(acceptedFiles[0])}
						onDropRejected={fileRejections =>
							showErrorToast({description: `${fileRejections[0]?.errors[0]?.message}. Please try again.`})
						}
						subtitle="Drag and drop here to upload a resume."
					/>
				)}
			</Dialog.Content>
		</Dialog>
	);
};
