import {useEffect, useState} from 'react';
import {useCopyToClipboard} from 'react-use';
import {IconClipboardCheck, IconClipboardCopy, IconClipboardX} from '@tabler/icons-react';

import Button from '../button';
import IconButton from '../icon-button';
import Tooltip from '../tooltip';
import type {ButtonProps} from '../button';

export type CopyButtonProps = Omit<ButtonProps, 'color' | 'iconLeft' | 'iconRight'> & {
	text: string;
	iconOnly?: true;
};

const CopyButton = ({text, iconOnly, circular, children, ...props}: CopyButtonProps) => {
	const [state, copyToClipboard] = useCopyToClipboard();
	const [showTooltip, setShowTooltip] = useState(false);

	const status = state.error ? 'Error' : state.value ? 'Copied' : 'Copy';
	const color = state.error ? 'danger' : state.value ? 'success' : 'neutral';

	useEffect(() => {
		if (state.value) {
			setShowTooltip(true);
			const timeout = setTimeout(() => setShowTooltip(false), 1500);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [state.value]);

	return iconOnly ? (
		<Tooltip open={showTooltip}>
			<Tooltip.Trigger asChild>
				<IconButton
					{...props}
					circular={circular}
					color={color}
					icon={
						!state.value && !state.error
							? IconClipboardCopy
							: state.value
								? IconClipboardCheck
								: IconClipboardX
					}
					onPress={() => copyToClipboard(text)}
				/>
			</Tooltip.Trigger>
			<Tooltip.Content>Copied!</Tooltip.Content>
		</Tooltip>
	) : (
		<Button
			circular={circular}
			iconRight={
				!state.value && !state.error ? IconClipboardCopy : state.value ? IconClipboardCheck : IconClipboardX
			}
			{...props}
			color={color}
			onPress={() => copyToClipboard(text)}
		>
			{children || status}
		</Button>
	);
};

CopyButton.displayName = 'CopyButton';

export default CopyButton;
