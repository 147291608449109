import {forwardRef, useEffect, useMemo} from 'react';
import type {DeleteResumeAlertProps} from '~/shared/components/alerts/delete-resume';
import {DeleteResumeAlert} from '~/shared/components/alerts/delete-resume';
import type {ResumeUploadDialogProps} from '~/shared/components/dialogs/resume-upload';
import {ResumeUploadDialog} from '~/shared/components/dialogs/resume-upload';
import {useResumes} from '~/shared/data/resume';
import {ButtonGroup, IconButton, Select} from '@job-ish/ui/components';
import {useModal} from '@job-ish/ui/hooks';
import {IconEdit, IconFileText, IconPlus, IconTrash} from '@tabler/icons-react';

type ResumeSelectProps = {
	value?: number | null;
	onValueChange: (value?: number | null) => void;
};

export const ResumeSelect = forwardRef<HTMLButtonElement, ResumeSelectProps>(
	({value, onValueChange}: ResumeSelectProps, forwardedRef) => {
		const {data: resumes} = useResumes();
		const {show: showDialog} = useModal();

		const selectedResume = useMemo(() => resumes?.find(resume => resume.id === value), [resumes, value]);

		useEffect(() => {
			if (value && !selectedResume) {
				onValueChange(null);
			}
		}, [value, onValueChange, selectedResume]);

		const handleValueChange = (value?: string) => {
			onValueChange(value ? Number(value) : null);
		};

		return (
			<ButtonGroup className="flex w-full max-w-full items-start">
				<Select onValueChange={handleValueChange} value={selectedResume?.id.toString() ?? undefined}>
					<Select.Trigger
						className="min-w-0 grow"
						clearable
						disabled={resumes?.length === 0}
						placeholder="Select a resume"
						prefix={<IconFileText className="h-4 w-4" />}
						ref={forwardedRef}
					>
						{selectedResume ? selectedResume.name : undefined}
					</Select.Trigger>
					<Select.Content className="z-50 w-80">
						{resumes?.map(resume => (
							<Select.Item key={resume.id} title={resume.name} value={resume.id.toString()}>
								{resume.name}
							</Select.Item>
						))}
					</Select.Content>
				</Select>
				{selectedResume ? (
					<>
						<IconButton
							icon={IconEdit}
							onPress={() =>
								showDialog<ResumeUploadDialogProps>(ResumeUploadDialog, {resume: selectedResume})
							}
						/>
						<IconButton
							icon={IconTrash}
							onPress={() =>
								showDialog<DeleteResumeAlertProps>(DeleteResumeAlert, {
									resume: selectedResume,
									onDeleted: () => onValueChange(null),
								})
							}
						/>
					</>
				) : (
					<IconButton
						icon={IconPlus}
						onPress={() =>
							showDialog<ResumeUploadDialogProps>(ResumeUploadDialog, {onUploaded: onValueChange})
						}
					/>
				)}
			</ButtonGroup>
		);
	},
);
