'use client';

import {fetchResumes} from '~/shared/api/resume';
import {QUERY_KEYS} from '~/shared/constants/keys';
import {useSupabase} from '~/shared/hooks/use-supabase';
import {getResumeFilePath} from '~/shared/utils/resume';
import {useToast} from '@job-ish/ui/hooks';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';

import type {Resume} from '@job-ish/database/types';
import type {SetRequired} from 'type-fest';

export const useResumes = () => {
	const {supabase} = useSupabase();
	return useQuery({
		queryKey: QUERY_KEYS.Resumes,
		queryFn: async () => {
			const {data} = await fetchResumes(supabase);
			return data ?? [];
		},
	});
};

export const useUpsertResume = () => {
	const {supabase} = useSupabase();

	const {show: showErrorToast} = useToast({
		accent: 'danger',
		accentPosition: 'left',
		title: 'Upload Failed',
		description: 'There was an error uploading your resume. Please try again.',
		duration: 1500,
	});

	return useMutation({
		mutationFn: async (data: SetRequired<Partial<Resume>, 'file_path' | 'name'>) => {
			const {data: resume, error} = await supabase
				.from('resumes')
				.upsert(data, {onConflict: 'id'})
				.select('*')
				.maybeSingle<Resume>();

			if (error) throw error;
			return resume;
		},
		onError: () => {
			showErrorToast();
		},
	});
};

export const useUploadResumeFile = () => {
	const {supabase, user} = useSupabase();

	const {show: showErrorToast} = useToast({
		accent: 'danger',
		accentPosition: 'left',
		title: 'Upload Failed',
		description: 'There was an error uploading your resume. Please try again.',
		duration: 1500,
	});

	return useMutation({
		mutationFn: async ({file, file_path}: {file: File; file_path: string}) => {
			if (!user) throw new Error('User not found');

			const {data, error} = await supabase.storage
				.from('resumes')
				.upload(getResumeFilePath({filePath: file_path, userId: user.id}), file, {upsert: true});

			if (error) throw error;
			return data;
		},
		onError: () => {
			showErrorToast();
		},
	});
};

export const useDeleteResume = () => {
	const queryClient = useQueryClient();
	const {supabase} = useSupabase();

	const {show: showErrorToast} = useToast({
		accent: 'danger',
		accentPosition: 'left',
		description: 'Resume could not be deleted. Please try again.',
		duration: 1500,
		title: 'Resume Deletion Failed',
	});

	return useMutation({
		mutationFn: async ({id}: {id: number}) => {
			const {data: resume, error} = await supabase.from('resumes').delete().match({id}).maybeSingle<Resume>();
			if (error) throw error;
			return resume;
		},
		onMutate: async ({id}: {id: number}) => {
			await queryClient.cancelQueries({queryKey: QUERY_KEYS.Resumes});
			const previousResumes = queryClient.getQueryData<Resume[]>(QUERY_KEYS.Resumes) ?? [];

			queryClient.setQueryData<Resume[]>(
				QUERY_KEYS.Resumes,
				previousResumes.filter(resume => resume.id !== id),
			);
			return {previousResumes};
		},
		onError: (_error, _variables, context) => {
			queryClient.setQueryData(QUERY_KEYS.Resumes, context?.previousResumes);
			showErrorToast();
		},
		onSuccess: async data => {
			if (data) {
				await supabase.storage.from('resumes').remove([data.file_path]);
			}
		},
		onSettled: async () => {
			await queryClient.invalidateQueries({queryKey: QUERY_KEYS.Resumes});
		},
	});
};
