import {useEffect} from 'react';
import {DeleteResumeForm} from '~/shared/components/forms/resume/delete';
import {useDeleteResume} from '~/shared/data/resume';
import {AlertDialog} from '@job-ish/ui/components';

import type {Resume} from '@job-ish/database/types';
import type {ReactNode} from 'react';

export type DeleteResumeAlertProps = {
	resume: Resume;
	open: boolean;
	onOpenChange: (open: boolean) => void;
	trigger?: ReactNode;
	onDeleted?: () => void;
};

export const DeleteResumeAlert = ({
	resume,
	open,
	onOpenChange,
	onDeleted,
	trigger,
}: DeleteResumeAlertProps) => {
	const {mutate: deleteResume, isSuccess} = useDeleteResume();

	const onSubmit = async () => {
		onOpenChange(false);
		deleteResume({id: resume.id});
	};

	useEffect(() => {
		if (isSuccess) {
			onDeleted?.();
		}
	}, [isSuccess, onDeleted]);

	return (
		<AlertDialog onOpenChange={onOpenChange} open={open}>
			<AlertDialog.Trigger asChild>{trigger}</AlertDialog.Trigger>
			<AlertDialog.Content className="md:w-[28rem]">
				<AlertDialog.Title>Delete Resume</AlertDialog.Title>
				<AlertDialog.Description>
					Are you sure you want to delete <span className="font-bold">{resume.name}</span>? This action cannot
					be undone.
				</AlertDialog.Description>
				<DeleteResumeForm onCancel={() => onOpenChange(false)} onSubmit={onSubmit} />
			</AlertDialog.Content>
		</AlertDialog>
	);
};
